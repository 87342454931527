var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading && _vm.errorModalVisible
    ? _c("ErrorModal", {
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      })
    : _vm.isLoading
    ? _c(
        "div",
        { staticClass: "loading-wrapper" },
        [_c("BaseLoadingSpinner", { attrs: { "with-overlay": false } })],
        1
      )
    : _c(
        "ApplicationContainerModal",
        _vm._g(
          {
            directives: [
              {
                name: "esc-click",
                rawName: "v-esc-click",
                value: _vm.handleEscClick,
                expression: "handleEscClick",
              },
            ],
          },
          _vm.$listeners
        ),
        [
          _c(
            "template",
            { slot: "modals" },
            [
              _vm.currentModal === "NOTES"
                ? _c("SubletNotesModal", {
                    attrs: {
                      "unread-notes": _vm.application.unreadNotes,
                      "application-id": `${_vm.application.id}`,
                      "read-only-mode": false,
                      notes: _vm.application.landlordNotes,
                    },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      addNote: _vm.handleAddNote,
                      deleteNote: _vm.handleDeleteNote,
                      editNote: _vm.handleEditNote,
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "DOCUMENTS"
                ? _c("SubletDocumentsModal", {
                    attrs: {
                      "application-id": `${_vm.application.id}`,
                      "applicant-name": _vm.activeCurrentTenant.name,
                      files: _vm.application.files,
                      "selected-type": _vm.selectedDocumentType,
                      "selected-applicant-id": _vm.selectedApplicantId,
                      "has-scrive-document": !!_vm.application.scriveId,
                    },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                        _vm.selectedApplicantId = null
                        _vm.selectedDocumentType = null
                      },
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "APPROVE"
                ? _c("SubletApprovalModal", {
                    attrs: {
                      "application-id": _vm.application.id,
                      "initial-start-date": _vm.application.startDate,
                      "initial-end-date": _vm.application.endDate,
                      "rent-from": _vm.application.reason.rentFrom,
                      "rent-to": _vm.application.reason.rentTo,
                    },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      onApproved: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "REJECT"
                ? _c("SubletRejectModal", {
                    attrs: { "application-id": _vm.application.id },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      onRejected: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
              _vm.selectedPartInfo !== null
                ? _c("SubletPartInfoModal", {
                    attrs: {
                      position: _vm.selectedPartInfoModalPosition,
                      "selected-info": _vm.selectedPartInfo,
                    },
                    on: {
                      close: function ($event) {
                        _vm.selectedPartInfo = null
                      },
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "USER_NOT_ASSIGNED"
                ? _c("NotAssignedModal", {
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      confirm: function ($event) {
                        _vm.currentModal = "REMOVE"
                      },
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "REMOVE"
                ? _c("SubletRemoveModal", {
                    attrs: { "application-id": _vm.application.id },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      onRemoved: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "CLOSE"
                ? _c("SubletCloseModal", {
                    attrs: { "application-id": _vm.application.id },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      cancelled: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "template",
            { slot: "header" },
            [
              _c(
                "TabHeader",
                {
                  attrs: {
                    "all-disabled": _vm.isAnimatingModalSize,
                    title: _vm.getReasonText,
                    subtitle: _vm.tabHeaderSubtitle,
                    "application-status": _vm.application.landlordStatus,
                    "show-help-button": false,
                    tabs: _vm.activeTabs,
                    "is-archived": _vm.isArchived,
                  },
                  on: { tabClick: _vm.setTab },
                },
                [
                  _vm.ahAppsChains[_vm.application.id]
                    ? _c(
                        "template",
                        { slot: "chain-link" },
                        [
                          _c("LinkChain", {
                            staticClass: "link-chain",
                            attrs: {
                              id: _vm.ahAppsChains[_vm.application.id],
                              text: _vm.application.latestVersion
                                ? _vm.getAhExtensionText(
                                    _vm.application.renewalReason
                                  )
                                : "",
                              reverse: _vm.application.latestVersion === 0,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.activeTab === 1
                ? [
                    _c(
                      "ApplicationsRow",
                      { attrs: { "two-columns": "" } },
                      [
                        _c(
                          "ApplicationCard",
                          [
                            _c(
                              "template",
                              { slot: "top-content" },
                              _vm._l(
                                _vm.currentTenants,
                                function (currentTenant, i) {
                                  return _c(
                                    "PersonCard",
                                    {
                                      key: currentTenant.name,
                                      attrs: {
                                        name: currentTenant.name,
                                        address: _vm.getApartmentAdress,
                                        "current-index": i,
                                      },
                                    },
                                    [
                                      _c("BaseImage", {
                                        staticClass: "icon",
                                        attrs: { url: _vm.svgs.ICONS.CONTACT },
                                        on: {
                                          click: (e) => {
                                            const x = e.clientX
                                            const y = e.clientY + 20
                                            _vm.openPartInfoModal({
                                              x,
                                              y,
                                              type: "currentTenants",
                                              index: currentTenant.index,
                                            })
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                            _vm.application.landlordStatus !== "INCOMING"
                              ? _c("h4", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.application.apartment.address
                                      ) +
                                      ", " +
                                      _vm._s(
                                        _vm.application.apartment.postalCode
                                      ) +
                                      " " +
                                      _vm._s(_vm.application.apartment.city) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                            _c("ul", { staticClass: "apartment-info" }, [
                              _c("li", [
                                _c("b", [_vm._v("Hyra:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.application.apartment.rent) +
                                    " kr"
                                ),
                              ]),
                              _c("li", [
                                _c("b", [_vm._v("Lägenhetsnr.:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.application.apartment.apartmentNumber
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("li", [
                                _c("b", [_vm._v("Objektnr.:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.application.apartment.objectNumber
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("li", [
                                _c("b", [
                                  _vm._v("Hyr ut lägenheten möblerad:"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.application.apartment.furnished
                                        ? "Ja"
                                        : "Nej"
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("hr", { staticClass: "divider" }),
                            _c("SubletApplicationCheckboxes", {
                              attrs: {
                                files: _vm.application.files,
                                application: _vm.application,
                                "application-id": _vm.application.id,
                                "checked-files": _vm.application.checkedFiles,
                                "other-checkboxes": _vm.otherCheckboxes,
                                "application-status":
                                  _vm.application.landlordStatus,
                              },
                              on: {
                                openFile: function ($event) {
                                  return _vm.openDocumentModal($event)
                                },
                                checksChange: function ($event) {
                                  return _vm.updateCheckedMember($event)
                                },
                              },
                            }),
                            _c("br"),
                            _c("div", { staticClass: "card-content" }, [
                              _c("h4", [_vm._v("Skäl")]),
                              _c("p", [_vm._v(_vm._s(_vm.getReasonText))]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "ApplicationCard",
                          [
                            _vm.application.reason.type === "other"
                              ? [
                                  _c("h4", [
                                    _vm._v("Boendeort under uthyrningstiden"),
                                  ]),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.application.reason.city)),
                                  ]),
                                  _c("hr", { staticClass: "divider" }),
                                  _c("h4", [_vm._v("Anledning")]),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.application.reason.text)),
                                  ]),
                                ]
                              : _vm._e(),
                            _vm.application.reason.type === "utlandsvistelse"
                              ? [
                                  _c("h4", [
                                    _vm._v("Boendeort under uthyrningstiden"),
                                  ]),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.application.reason.city)),
                                  ]),
                                  _vm.application.reason.text
                                    ? [
                                        _c("hr", { staticClass: "divider" }),
                                        _c("h4", [_vm._v("Övrig information")]),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(_vm.application.reason.text)
                                          ),
                                        ]),
                                      ]
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            _vm.application.reason.type === "arbete" ||
                            _vm.application.reason.type === "studie"
                              ? [
                                  _c("h4", [
                                    _vm._v("Boendeort under uthyrningstiden"),
                                  ]),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.application.reason.city)),
                                  ]),
                                ]
                              : _vm.application.reason.type === "sambo"
                              ? [
                                  _c("h4", [
                                    _vm._v("Adress under uthyrningstiden"),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.application.reason.address) +
                                        ", " +
                                        _vm._s(
                                          _vm.application.reason.postalCode
                                        ) +
                                        " " +
                                        _vm._s(_vm.application.reason.city) +
                                        " "
                                    ),
                                  ]),
                                  _c("hr", { staticClass: "divider" }),
                                  _c("h4", [_vm._v("Boendeform")]),
                                  _c("ul", { staticClass: "apartment-info" }, [
                                    _c("li", [
                                      _c("b", [_vm._v("Boendeform:")]),
                                      _vm._v(
                                        " " + _vm._s(_vm.housingTypeTitle)
                                      ),
                                    ]),
                                    _c("li", [
                                      _c("b", [_vm._v("Hyresvärd:")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.application.reason.houseOwner
                                          )
                                      ),
                                    ]),
                                    _c("li", [
                                      _c("b", [
                                        _vm._v("Telefonnummer till hyresvärd:"),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.application.reason.ownerPhone
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("li", [
                                      _c("b", [
                                        _vm._v("Email-adress till hyresvärd:"),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.application.reason.ownerEmail
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c("hr", { staticClass: "divider" }),
                                  _c("h4", [
                                    _vm._v(
                                      "Uppgifter på den du ska provbo med"
                                    ),
                                  ]),
                                  _c("ul", { staticClass: "apartment-info" }, [
                                    _c("li", [
                                      _c("b", [_vm._v("Namn:")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.application.reason.partnerName
                                          )
                                      ),
                                    ]),
                                    _c("li", [
                                      _c("b", [_vm._v("Personnr.:")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.application.reason.partnerSsn
                                          )
                                      ),
                                    ]),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.activeTab === 2
                ? [
                    _c(
                      "ApplicationsRow",
                      { attrs: { "two-columns": "" } },
                      _vm._l(_vm.secondaryTenants, function (secondaryTenant) {
                        return _c(
                          "ApplicationCard",
                          { key: secondaryTenant.name, attrs: { flex: "" } },
                          [
                            _c(
                              "template",
                              { slot: "top-content" },
                              [
                                _c(
                                  "PersonCard",
                                  {
                                    attrs: {
                                      name: secondaryTenant.name,
                                      address: secondaryTenant.address,
                                    },
                                  },
                                  [
                                    _c("BaseImage", {
                                      staticClass: "icon",
                                      attrs: { url: _vm.svgs.ICONS.CONTACT },
                                      on: {
                                        click: (e) => {
                                          const x = e.clientX
                                          const y = e.clientY + 20
                                          _vm.openPartInfoModal({
                                            x,
                                            y,
                                            type: "secondaryTenants",
                                            index: secondaryTenant.index,
                                          })
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "h3",
                              {
                                key: secondaryTenant.name,
                                staticClass: "name capitalize-first",
                              },
                              [_vm._v(" " + _vm._s(secondaryTenant.name) + " ")]
                            ),
                            _c("ul", { staticClass: "apartment-info" }, [
                              _c("li", [
                                _c("b", [_vm._v("Adress:")]),
                                _vm._v(" " + _vm._s(secondaryTenant.address)),
                              ]),
                              _c("li", [
                                _c("b", [_vm._v("Telefonnr.:")]),
                                _vm._v(
                                  " " + _vm._s(secondaryTenant.phone) + " "
                                ),
                              ]),
                              _c("li", [
                                _c("b", [_vm._v("Personnr.:")]),
                                _vm._v(" " + _vm._s(secondaryTenant.ssn)),
                              ]),
                              _c("li", [
                                _c("b", [_vm._v("E-post:")]),
                                _vm._v(" " + _vm._s(secondaryTenant.email)),
                              ]),
                            ]),
                          ],
                          2
                        )
                      }),
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.activeTab === 3
                ? [
                    _vm.userHistory.length
                      ? _c(
                          "div",
                          { staticClass: "grid" },
                          _vm._l(
                            _vm.userHistory,
                            function (userApp, userAppIndex) {
                              return _c(
                                "div",
                                {
                                  key: userApp.id + userAppIndex,
                                  staticClass: "application-card-wrapper",
                                },
                                [
                                  _c("SubletApplicationCard", {
                                    attrs: {
                                      application: userApp,
                                      "reason-type-title": _vm.reasonText(
                                        userApp.reason
                                      ),
                                      "is-new": false,
                                      "enable-link":
                                        _vm.currentApplicationId !== userApp.id,
                                      "full-name":
                                        userApp.assignedLandlordFirstName,
                                      "last-name":
                                        userApp.assignedLandlordLastName,
                                      "status-date": "",
                                      "show-tag-button": false,
                                      "show-days-left": false,
                                      "to-path": _vm.getToPath(userApp.id),
                                      "show-chain-info": false,
                                    },
                                  }),
                                  _vm.currentApplicationId === userApp.id
                                    ? _c(
                                        "p",
                                        { staticClass: "is-same-card" },
                                        [
                                          _c("ChevronDownIcon"),
                                          _c("span", [
                                            _vm._v("Du har detta ärende öppet"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      : _c("div", [
                          _c("h3", { staticStyle: { "font-size": "2rem" } }, [
                            _vm._v(
                              " Ingen historik finns ännu, denna användare har endast två länkade ärenden. "
                            ),
                          ]),
                        ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c(
                  "BaseItemsList",
                  {
                    class: {
                      "no-pointer-events": _vm.hasRemovalTypeStatus,
                    },
                    attrs: {
                      items: _vm.landlordUsersWithUnAssign.filter(
                        (x) => !x.inactivatedAt
                      ),
                      "show-contact": true,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.assignLandlordUser($event)
                      },
                    },
                  },
                  [
                    !_vm.application.assignedLandlordUserId
                      ? _c(
                          "BaseIconText",
                          {
                            staticClass: "assign-button",
                            attrs: {
                              "icon-url": _vm.svgs.ICONS.ADMINISTRATOR,
                              size: "larger",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.hasRemovalTypeStatus
                                    ? "Ej tilldelad"
                                    : "Tilldela handläggare"
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _c("UserIcon", {
                          attrs: {
                            "first-name":
                              _vm.application.assignedLandlordFirstName,
                            "last-name":
                              _vm.application.assignedLandlordLastName,
                            "full-name-visible": true,
                          },
                        }),
                  ],
                  1
                ),
                !_vm.isIncoming && !_vm.hasRemovalTypeStatus
                  ? _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "simple-button notes-button",
                          on: {
                            click: function ($event) {
                              _vm.currentModal = "NOTES"
                            },
                          },
                        },
                        [
                          _c(
                            "BaseIconText",
                            {
                              attrs: {
                                "icon-url": _vm.svgs.ICONS.NOTEBOOK,
                                size: "larger",
                              },
                            },
                            [
                              false
                                ? _c("NotificationSymbol", {
                                    attrs: {
                                      slot: "icon",
                                      "border-color": "#f1f2f6",
                                    },
                                    slot: "icon",
                                  })
                                : _vm._e(),
                              _vm._v("Noteringar"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                !_vm.isIncoming && !_vm.hasRemovalTypeStatus
                  ? _c(
                      "button",
                      {
                        staticClass: "simple-button files-button",
                        on: {
                          click: function ($event) {
                            _vm.currentModal = "DOCUMENTS"
                          },
                        },
                      },
                      [
                        _c(
                          "BaseIconText",
                          {
                            attrs: {
                              "icon-url": _vm.svgs.ICONS.ATTACHMENT,
                              size: "larger",
                            },
                          },
                          [
                            _vm._v(
                              "Visa " +
                                _vm._s(_vm.application.files?.length || 0) +
                                " filer "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "right" },
              [
                !_vm.isIncoming && !_vm.hasRemovalTypeStatus
                  ? _c(
                      "div",
                      { staticClass: "popupModal-container mr-15" },
                      [
                        _vm.popupModalVisible
                          ? _c("PopupModal", {
                              attrs: { items: _vm.itemsInPopupModal },
                              on: {
                                close: function ($event) {
                                  _vm.popupModalVisible = false
                                },
                                click: _vm.handlePopupClick,
                              },
                            })
                          : _vm._e(),
                        _c(
                          "button",
                          {
                            staticClass: "simple-button notes-button",
                            on: {
                              click: function ($event) {
                                _vm.popupModalVisible = !_vm.popupModalVisible
                              },
                            },
                          },
                          [
                            _c(
                              "BaseIconText",
                              { attrs: { "icon-url": _vm.svgs.ICONS.MORE } },
                              [_vm._v("Fler val")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.application.landlordStatus === "READY"
                  ? [
                      _c(
                        "BaseButton",
                        {
                          staticClass: "mr-15",
                          attrs: {
                            "is-warning": true,
                            "is-rounded": true,
                            icon: _vm.svgs.ICONS.ERROR_WHITE,
                          },
                          on: {
                            click: function ($event) {
                              _vm.currentModal = "REJECT"
                            },
                          },
                        },
                        [_vm._v(" Neka ansökan ")]
                      ),
                      _c(
                        "BaseButton",
                        {
                          attrs: {
                            "is-rounded": true,
                            icon: _vm.svgs.ICONS.CHECK_WHITE,
                          },
                          on: {
                            click: function ($event) {
                              _vm.currentModal = "APPROVE"
                            },
                          },
                        },
                        [_vm._v(" Godkänn ansökan ")]
                      ),
                    ]
                  : _vm.application.landlordStatus === "APPROVED"
                  ? _c(
                      "BaseButton",
                      {
                        attrs: {
                          "is-warning": true,
                          "is-rounded": true,
                          icon: _vm.svgs.ICONS.ERROR_WHITE,
                        },
                        on: {
                          click: function ($event) {
                            _vm.currentModal = "CLOSE"
                          },
                        },
                      },
                      [_vm._v(" Avsluta uthyrning ")]
                    )
                  : _vm._e(),
                _vm.showRejectReasonModal
                  ? _c(
                      "DefaultModal",
                      {
                        attrs: { "show-close-btn": false },
                        on: {
                          close: function ($event) {
                            _vm.rejectReasonModalVisible = false
                          },
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "header" },
                          [_c("DefaultModalTitle", [_vm._v("Nekat byte")])],
                          1
                        ),
                        _c("template", { slot: "body" }, [
                          _c("div", { staticClass: "reject-reason" }, [
                            _c("p", { staticClass: "explanation" }, [
                              _vm._v("Förklaring:"),
                            ]),
                            _vm.landlordRejectionNote.text
                              ? _c("span", [
                                  _vm._v(
                                    '"' +
                                      _vm._s(_vm.landlordRejectionNote.text) +
                                      '"'
                                  ),
                                ])
                              : _c("span", [_vm._v("(Förklaring saknas)")]),
                            _vm.landlordRejectionNote.firstName
                              ? _c("p", [
                                  _vm._v(
                                    " - " +
                                      _vm._s(
                                        _vm.landlordRejectionNote.firstName
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.landlordRejectionNote.lastName
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("p", [_vm._v("(Namn saknas)")]),
                          ]),
                        ]),
                        _c(
                          "template",
                          { slot: "footer" },
                          [
                            _c(
                              "BaseButton",
                              {
                                attrs: {
                                  "is-rounded": true,
                                  "is-default": true,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.rejectReasonModalVisible = false
                                  },
                                },
                              },
                              [_vm._v("Stäng")]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }